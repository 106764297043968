



import { Component, Prop, Vue } from 'vue-property-decorator';

import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'ImageCard',
    components: {}
})
export default class Login extends Vue {
    @Prop({ type: String, default: '' }) public src;

    public prefix: any = this.appConfig.vueSrc;

    public get appConfig() {
        return appConfigModule.appConfig;
    }
}
