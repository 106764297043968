






import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    name: 'TitleStyle1',
    components: {}
})
export default class TitleStyle1 extends Vue {
    @Prop({
        type: String,
        default: () => ''
    })
    public title: string;

    @Prop({
        type: Boolean,
        default: () => true
    })
    public show: boolean;
}
