import TitleCard from '@/components/PortalSite/TitleCard/index.vue';
import TitleLineCard from '@/components/PortalSite/TitleCard/title-line.vue';
import TitleStyleCard from '@/components/PortalSite/TitleCard/title-style.vue';
import TitleStyle1Card from '@/components/PortalSite/TitleCard/title-style1.vue';
import ImageCard from '@/components/ywhzImageCard/index.vue';

const YwhzComponents = {
    install: (Vue: any, option: any) => {
        const name: string = option.componentPrefix;
        Vue.component(`${name}TitleCard`, TitleCard);
        Vue.component(`${name}TitleLineCard`, TitleLineCard);
        Vue.component(`${name}TitleStyleCard`, TitleStyleCard);
        Vue.component(`${name}TitleStyle1Card`, TitleStyle1Card);
        Vue.component(`${name}ImageCard`, ImageCard);
    }
};
export default YwhzComponents;
