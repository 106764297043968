









// <ywhz-title-card title="台集運 · 線路介紹" subtitle="TAIWAN CONSOLIDATION"></ywhz-title-card>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'TitleStyle',
    components: {}
})
export default class TitleStyle extends Vue {
    @Prop({
        type: String,
        default: () => ''
    })
    public title: string;

    @Prop({
        type: String,
        default: () => ''
    })
    public subtitle: string;
}
